export const statesTaxSavings = [
  {
    state: 'Alabama',
    stateCode: 'AL',
    taxSavings: 0,
    fallbackMessage: '',
  },
  {
    state: 'Alaska',
    stateCode: 'AK',
    taxSavings: 0,
    fallbackMessage: '',
  },
  {
    state: 'American Samoa',
    stateCode: 'AS',
    taxSavings: 0,
    fallbackMessage: '',
  },
  {
    state: 'Arizona',
    stateCode: 'AZ',
    taxSavings: 0.16,
    fallbackMessage: '',
  },
  {
    state: 'Arkansas',
    stateCode: 'AR',
    taxSavings: 0,
    fallbackMessage:
      'Your state does not offer tax savings, but having a medical card is the only way to legally purchase, use, and possess cannabis.',
  },
  {
    state: 'California',
    stateCode: 'CA',
    taxSavings: 0.0725,
    fallbackMessage: '',
  },
  {
    state: 'Colorado',
    stateCode: 'CO',
    taxSavings: 0.15,
    fallbackMessage: '',
  },
  {
    state: 'Connecticut',
    stateCode: 'CT',
    taxSavings: 0.24,
    fallbackMessage: '',
  },
  {
    state: 'Delaware',
    stateCode: 'DE',
    taxSavings: 0.15,
    fallbackMessage: '',
  },
  {
    state: 'Florida',
    stateCode: 'FL',
    taxSavings: 0,
    fallbackMessage:
      'Your state does not offer tax savings, but having a medical card is the only way to legally purchase, use, and possess cannabis.',
  },
  {
    state: 'Georgia',
    stateCode: 'GA',
    taxSavings: 0,
    fallbackMessage:
      'Your state does not offer tax savings, but having a low-THC oil card is the only way to legally purchase, use, and possess low-THC oil.',
  },
  {
    state: 'Guam',
    stateCode: 'GU',
    taxSavings: 0,
    fallbackMessage: '',
  },
  {
    state: 'Hawaii',
    stateCode: 'HI',
    taxSavings: 0,
    fallbackMessage: '',
  },
  {
    state: 'Idaho',
    stateCode: 'ID',
    taxSavings: 0,
    fallbackMessage: '',
  },
  {
    state: 'Illinois',
    stateCode: 'IL',
    taxSavings: 0.34,
    fallbackMessage: '',
  },
  {
    state: 'Indiana',
    stateCode: 'IN',
    taxSavings: 0,
    fallbackMessage: '',
  },
  {
    state: 'Iowa',
    stateCode: 'IA',
    taxSavings: 0,
    fallbackMessage:
      'Your state does not offer tax savings, but having a low-THC card is the only way to legally purchase, use, and possess low-THC products.',
  },
  {
    state: 'Kansas',
    stateCode: 'KS',
    taxSavings: 0,
    fallbackMessage: '',
  },
  {
    state: 'Kentucky',
    stateCode: 'KY',
    taxSavings: 0,
    fallbackMessage:
      'Your state does not offer tax savings, but having a medical card is the only way to legally purchase, use, and possess cannabis.',
  },
  {
    state: 'Louisiana',
    stateCode: 'LA',
    taxSavings: 0,
    fallbackMessage:
      'Your state does not offer tax savings, but having a medical card is the only way to legally purchase, use, and possess cannabis.',
  },
  {
    state: 'Maine',
    stateCode: 'ME',
    taxSavings: 0.1,
    fallbackMessage: '',
  },
  {
    state: 'Maryland',
    stateCode: 'MD',
    taxSavings: 0.09,
    fallbackMessage: '',
  },
  {
    state: 'Massachusetts',
    stateCode: 'MA',
    taxSavings: 0.2,
    fallbackMessage: '',
  },
  {
    state: 'Michigan',
    stateCode: 'MI',
    taxSavings: 0.1,
    fallbackMessage: '',
  },
  {
    state: 'Minnesota',
    stateCode: 'MN',
    taxSavings: 0.1,
    fallbackMessage: '',
  },
  {
    state: 'Mississippi',
    stateCode: 'MS',
    taxSavings: 0,
    fallbackMessage:
      'Your state does not offer tax savings, but having a medical card is the only way to legally purchase, use, and possess cannabis.',
  },
  {
    state: 'Missouri',
    stateCode: 'MO',
    taxSavings: 0.08,
    fallbackMessage: '',
  },
  {
    state: 'Montana',
    stateCode: 'MT',
    taxSavings: 0.16,
    fallbackMessage: '',
  },
  {
    state: 'Nebraska',
    stateCode: 'NE',
    taxSavings: 0,
    fallbackMessage: '',
  },
  {
    state: 'Nevada',
    stateCode: 'NV',
    taxSavings: 0.1,
    fallbackMessage: '',
  },
  {
    state: 'New Hampshire',
    stateCode: 'NH',
    taxSavings: 0,
    fallbackMessage:
      'Your state does not offer tax savings, but having a medical card is the only way to legally purchase, use, and possess cannabis.',
  },
  {
    state: 'New Jersey',
    stateCode: 'NJ',
    taxSavings: 0.07,
    fallbackMessage: '',
  },
  {
    state: 'New Mexico',
    stateCode: 'NM',
    taxSavings: 0.12,
    fallbackMessage: '',
  },
  {
    state: 'New York',
    stateCode: 'NY',
    taxSavings: 0.06,
    fallbackMessage: '',
  },
  {
    state: 'North Carolina',
    stateCode: 'NC',
    taxSavings: 0,
    fallbackMessage: '',
  },
  {
    state: 'North Dakota',
    stateCode: 'ND',
    taxSavings: 0,
    fallbackMessage: '',
  },
  {
    state: 'Northern Mariana Islands',
    stateCode: 'MP',
    taxSavings: 0,
    fallbackMessage: '',
  },
  {
    state: 'Ohio',
    stateCode: 'OH',
    taxSavings: 0.1,
    fallbackMessage: '',
  },
  {
    state: 'Oklahoma',
    stateCode: 'OK',
    taxSavings: 0,
    fallbackMessage:
      'Your state does not offer tax savings, but having a medical card is the only way to legally purchase, use, possess, and grow cannabis.',
  },
  {
    state: 'Oregon',
    stateCode: 'OR',
    taxSavings: 0.2,
    fallbackMessage: '',
  },
  {
    state: 'Pennsylvania',
    stateCode: 'PA',
    taxSavings: 0,
    fallbackMessage:
      'Your state does not offer tax savings, but having a medical card is the only way to legally purchase, use, and possess cannabis.',
  },
  {
    state: 'Puerto Rico',
    stateCode: 'PR',
    taxSavings: 0,
    fallbackMessage:
      'Your state does not offer tax savings, but having a medical card is the only way to legally purchase, use, and possess cannabis.',
  },
  {
    state: 'Rhode Island',
    stateCode: 'RI',
    taxSavings: 0.1,
    fallbackMessage: '',
  },
  {
    state: 'South Carolina',
    stateCode: 'SC',
    taxSavings: 0,
    fallbackMessage: '',
  },
  {
    state: 'South Dakota',
    stateCode: 'SD',
    taxSavings: 0,
    fallbackMessage: '',
  },
  {
    state: 'Tennessee',
    stateCode: 'TN',
    taxSavings: 0,
    fallbackMessage: '',
  },
  {
    state: 'Texas',
    stateCode: 'TX',
    taxSavings: 0,
    fallbackMessage:
      'Your state does not offer tax savings, but having a medical card is the only way to legally purchase, use, and possess cannabis.',
  },
  {
    state: 'US Virgin Islands',
    stateCode: 'VI',
    taxSavings: 0.18,
    fallbackMessage: '',
  },
  {
    state: 'Utah',
    stateCode: 'UT',
    taxSavings: 0,
    fallbackMessage:
      'Your state does not offer tax savings, but having a medical card is the only way to legally purchase, use and possess cannabis.',
  },
  {
    state: 'Vermont',
    stateCode: 'VT',
    taxSavings: 0.2,
    fallbackMessage: '',
  },
  {
    state: 'Virginia',
    stateCode: 'VA',
    taxSavings: 0.24,
    fallbackMessage: '',
  },
  {
    state: 'Washington',
    stateCode: 'WA',
    taxSavings: 0.37,
    fallbackMessage: '',
  },
  {
    state: 'Washington DC',
    stateCode: 'DC',
    taxSavings: 0.06,
    fallbackMessage: '',
  },
  {
    state: 'West Virginia',
    stateCode: 'WV',
    taxSavings: 0,
    fallbackMessage: '',
  },
  {
    state: 'Wisconsin',
    stateCode: 'WI',
    taxSavings: 0,
    fallbackMessage: '',
  },
  {
    state: 'Wyoming',
    stateCode: 'WY',
    taxSavings: 0,
    fallbackMessage: '',
  },
];
