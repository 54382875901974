type statesLegalStatusType = {
  state?: string;
  stateCode?: string;
  legalStatus?: 'Medical only' | 'Medical and recreational' | 'Illegal' | null;
};

export const statesLegalStatus: statesLegalStatusType[] = [
  {
    state: 'Alabama',
    stateCode: 'AL',
    legalStatus: 'Medical only',
  },
  {
    state: 'Alaska',
    stateCode: 'AK',
    legalStatus: 'Medical and recreational',
  },
  {
    state: 'American Samoa',
    stateCode: 'AS',
    legalStatus: 'Illegal',
  },
  {
    state: 'Arizona',
    stateCode: 'AZ',
    legalStatus: 'Medical and recreational',
  },
  {
    state: 'Arkansas',
    stateCode: 'AR',
    legalStatus: 'Medical only',
  },
  {
    state: 'California',
    stateCode: 'CA',
    legalStatus: 'Medical and recreational',
  },
  {
    state: 'Colorado',
    stateCode: 'CO',
    legalStatus: 'Medical and recreational',
  },
  {
    state: 'Connecticut',
    stateCode: 'CT',
    legalStatus: 'Medical and recreational',
  },
  {
    state: 'Delaware',
    stateCode: 'DE',
    legalStatus: 'Medical and recreational',
  },
  {
    state: 'Florida',
    stateCode: 'FL',
    legalStatus: 'Medical only',
  },
  {
    state: 'Georgia',
    stateCode: 'GA',
    legalStatus: 'Medical only',
  },
  {
    state: 'Guam',
    stateCode: 'GU',
    legalStatus: 'Illegal',
  },
  {
    state: 'Hawaii',
    stateCode: 'HI',
    legalStatus: 'Medical only',
  },
  {
    state: 'Idaho',
    stateCode: 'ID',
    legalStatus: 'Illegal',
  },
  {
    state: 'Illinois',
    stateCode: 'IL',
    legalStatus: 'Medical and recreational',
  },
  {
    state: 'Indiana',
    stateCode: 'IN',
    legalStatus: 'Illegal',
  },
  {
    state: 'Iowa',
    stateCode: 'IA',
    legalStatus: 'Medical only',
  },
  {
    state: 'Kansas',
    stateCode: 'KS',
    legalStatus: 'Illegal',
  },
  {
    state: 'Kentucky',
    stateCode: 'KY',
    legalStatus: 'Medical only',
  },
  {
    state: 'Louisiana',
    stateCode: 'LA',
    legalStatus: 'Medical only',
  },
  {
    state: 'Maine',
    stateCode: 'ME',
    legalStatus: 'Medical and recreational',
  },
  {
    state: 'Maryland',
    stateCode: 'MD',
    legalStatus: 'Medical and recreational',
  },
  {
    state: 'Massachusetts',
    stateCode: 'MA',
    legalStatus: 'Medical and recreational',
  },
  {
    state: 'Michigan',
    stateCode: 'MI',
    legalStatus: 'Medical and recreational',
  },
  {
    state: 'Minnesota',
    stateCode: 'MN',
    legalStatus: 'Medical and recreational',
  },
  {
    state: 'Mississippi',
    stateCode: 'MS',
    legalStatus: 'Medical only',
  },
  {
    state: 'Missouri',
    stateCode: 'MO',
    legalStatus: 'Medical and recreational',
  },
  {
    state: 'Montana',
    stateCode: 'MT',
    legalStatus: 'Medical and recreational',
  },
  {
    state: 'Nebraska',
    stateCode: 'NE',
    legalStatus: 'Illegal',
  },
  {
    state: 'Nevada',
    stateCode: 'NV',
    legalStatus: 'Medical and recreational',
  },
  {
    state: 'New Hampshire',
    stateCode: 'NH',
    legalStatus: 'Medical only',
  },
  {
    state: 'New Jersey',
    stateCode: 'NJ',
    legalStatus: 'Medical and recreational',
  },
  {
    state: 'New Mexico',
    stateCode: 'NM',
    legalStatus: 'Medical and recreational',
  },
  {
    state: 'New York',
    stateCode: 'NY',
    legalStatus: 'Medical and recreational',
  },
  {
    state: 'North Carolina',
    stateCode: 'NC',
    legalStatus: 'Illegal',
  },
  {
    state: 'North Dakota',
    stateCode: 'ND',
    legalStatus: 'Medical only',
  },
  {
    state: 'Northern Mariana Islands',
    stateCode: 'MP',
    legalStatus: 'Illegal',
  },
  {
    state: 'Ohio',
    stateCode: 'OH',
    legalStatus: 'Medical and recreational',
  },
  {
    state: 'Oklahoma',
    stateCode: 'OK',
    legalStatus: 'Medical only',
  },
  {
    state: 'Oregon',
    stateCode: 'OR',
    legalStatus: 'Medical and recreational',
  },
  {
    state: 'Pennsylvania',
    stateCode: 'PA',
    legalStatus: 'Medical only',
  },
  {
    state: 'Puerto Rico',
    stateCode: 'PR',
    legalStatus: 'Medical only',
  },
  {
    state: 'Rhode Island',
    stateCode: 'RI',
    legalStatus: 'Medical and recreational',
  },
  {
    state: 'South Carolina',
    stateCode: 'SC',
    legalStatus: 'Illegal',
  },
  {
    state: 'South Dakota',
    stateCode: 'SD',
    legalStatus: 'Medical only',
  },
  {
    state: 'Tennessee',
    stateCode: 'TN',
    legalStatus: 'Illegal',
  },
  {
    state: 'Texas',
    stateCode: 'TX',
    legalStatus: 'Medical only',
  },
  {
    state: 'US Virgin Islands',
    stateCode: 'VI',
    legalStatus: 'Medical and recreational',
  },
  {
    state: 'Utah',
    stateCode: 'UT',
    legalStatus: 'Medical only',
  },
  {
    state: 'Vermont',
    stateCode: 'VT',
    legalStatus: 'Medical and recreational',
  },
  {
    state: 'Virginia',
    stateCode: 'VA',
    legalStatus: 'Medical and recreational',
  },
  {
    state: 'Washington',
    stateCode: 'WA',
    legalStatus: 'Medical and recreational',
  },
  {
    state: 'Washington DC',
    stateCode: 'DC',
    legalStatus: 'Medical and recreational',
  },
  {
    state: 'West Virginia',
    stateCode: 'WV',
    legalStatus: 'Medical only',
  },
  {
    state: 'Wisconsin',
    stateCode: 'WI',
    legalStatus: 'Illegal',
  },
  {
    state: 'Wyoming',
    stateCode: 'WY',
    legalStatus: 'Illegal',
  },
];
