import React, { useMemo, useState } from 'react';
import type { StatesListItem } from 'hooks/useStatesList';

import {
  Form,
  Button,
  TextFieldControlled as Input,
  SelectFieldMinimal,
  FieldWrap,
  ButtonClose,
} from '@leafwell/components';

import { useStatesList } from 'hooks/useStatesList';
import { useCurrentState } from 'hooks/useCurrentState';
import { setStateCookie } from 'lib/cookies/state-cookie';
import { useWordPressContext } from 'contexts/wordpress-provider';

import { statesTaxSavings } from 'lib/data/tax-savings';
import { statesLegalStatus } from 'lib/data/states-legal-status';
import HeadingBody from './heading-body';
import { useEventsTracker } from 'contexts/eventsTracker';

const TaxSavingsForm: React.FC<{
  isBlogPost?: boolean;
  initialState?: string;
}> = ({ isBlogPost = false, initialState }) => {
  const { trackEvent } = useEventsTracker();
  const { cardPage, statePages, translations } = useWordPressContext();
  const defaultState = useCurrentState();

  const [selectedState, setSelectedState] = useState<string>(
    initialState || defaultState || '',
  );
  const statesList = useStatesList(false);
  const defaultStatePageUri = initialState
    ? statePages.find(({ code }) => code === initialState)?.uri
    : statePages.find(({ code }) => code === defaultState)?.uri;

  const selectedStateObject: StatesListItem = useMemo(
    () => statesList.find(item => item.value === selectedState),
    [statesList, selectedState],
  );

  const [formSubmitted, setFormSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [statePageUri, setStatePageUri] = useState<string>(defaultStatePageUri);
  const [taxSavings, setTaxSavings] = useState<number>(null);
  const [fallbackMessage, setFallbackMessage] = useState<string>('');
  const [legalStatus, setLegalStatus] = useState<
    'Medical only' | 'Medical and recreational' | 'Illegal' | null
  >(null);

  const onSubmit = ({ stateCode, avgSpend }, { clearErrors, setError }) => {
    setIsLoading(true);
    clearErrors();

    if (!stateCode) {
      setError('stateCode', {
        type: 'client',
        message: 'Invalid state',
      });
      setIsLoading(false);
      return;
    }

    if (avgSpend > 0) {
      const stateTaxInfo = statesTaxSavings.find(
        ({ stateCode: fileStateCode }) => fileStateCode === selectedState,
      );
      const stateTaxSaving = stateTaxInfo?.taxSavings;
      const fallbackMessage = stateTaxInfo?.fallbackMessage;

      if (stateTaxSaving > 0) {
        //[average monthly spend on cannabis products]*[tax saving in that state]*12
        setTaxSavings(avgSpend * stateTaxSaving * 12);
      } else {
        setTaxSavings(null);
        setFallbackMessage(fallbackMessage);
        setLegalStatus(
          statesLegalStatus.find(
            ({ stateCode: fileStateCode }) => fileStateCode === selectedState,
          )?.legalStatus,
        );
      }
    }
    setIsLoading(false);
    setFormSubmitted(true);
  };
  return (
    <>
      <Form
        className="grid gap-y-4"
        mode="onSubmit"
        defaultValues={{
          stateCode: selectedState,
        }}
        onSubmit={onSubmit}
      >
        <div className="grid gap-y-2 js-form-field relative">
          <p className="font-medium">Select your state</p>
          <FieldWrap name="stateCode">
            <SelectFieldMinimal
              aria-label="Select your state"
              defaultValue={selectedStateObject}
              placeholder="Select your state"
              options={statesList}
              disabled={false}
              onSelect={target => {
                setSelectedState(target.value);
                setStateCookie(target.value);
                setStatePageUri(
                  statePages.find(({ code }) => code === target.value)?.uri,
                );
                setFormSubmitted(false);
              }}
            />
          </FieldWrap>
        </div>
        <div className="grid gap-y-2 js-form-field relative">
          <p className="font-medium">
            Average monthly spend on cannabis product
          </p>
          <Input
            className="rounded-lg"
            name="avgSpend"
            placeholder="Insert value (in $)"
            type="number"
            validations={['required', 'number']}
          />
        </div>
        <div className="c-form__actions mt-2">
          <Button
            disabled={false}
            type="submit"
            label="Calculate your savings"
            variant="primary"
            className="w-full !text-lg rounded-lg font-medium font-sans"
            loading={isLoading}
            onClick={() => {
              trackEvent({
                event: 'select_content',
                content_type: translations[selectedState],
                item_id: 'calculate_tax_savings',
                origin: isBlogPost ? 'blog_post' : 'page',
              });
            }}
          />
        </div>
      </Form>
      {formSubmitted && taxSavings !== null && taxSavings > 0 ? (
        <>
          <p className="text-xl text-center">
            You could save <strong>${taxSavings.toFixed(0)}</strong> per year!
          </p>
          <Button
            href={statePageUri}
            label="Get your card now"
            variant="secondary"
            className="w-full !text-lg rounded-lg font-medium font-sans"
            onClick={() => {
              trackEvent({
                event: 'select_content',
                content_type: translations[selectedState],
                item_id: 'apply_medical_card',
                origin: 'tax_calculator',
              });
            }}
          />
          <p className="text-sm">
            Note that this is an estimate of savings and does not constitute a
            guarantee. Additional taxes in your area or recent changes in local
            laws may affect these rates.
          </p>
        </>
      ) : null}
      {formSubmitted && taxSavings === null ? (
        <>
          <p className="text-xl">
            {legalStatus === 'Illegal'
              ? fallbackMessage !== ''
                ? fallbackMessage
                : `There are no medical or recreational sales in ${translations[selectedState]}, so there are no tax savings.`
              : legalStatus === 'Medical only'
              ? fallbackMessage !== ''
                ? fallbackMessage
                : `There are no recreational sales in ${translations[selectedState]}, so there are no tax savings for card holders.`
              : selectedState == 'AK'
              ? 'In Alaska, effective January 1, 2019, sales and transfers of marijuana are subject to new tax rates. Mature bud/flower is taxed at $50 per ounce and trim is taxed at $15 per ounce. A new rate has been added at $25 per ounce for bud/flower that is considered immature or abnormal. This is bud/flower that did not fully mature or develop, contains seeds, or failed testing. Clones are taxed at a flat rate of $1 per clone and not on the estimated weight.'
              : `You already have the best deal for tax savings in ${translations[selectedState]}`}
          </p>
          {fallbackMessage !== '' ? (
            <Button
              href={statePageUri}
              label="Get your card now"
              variant="secondary"
              className="w-full !text-lg rounded-lg font-medium font-sans"
              onClick={() => {
                trackEvent({
                  event: 'select_content',
                  content_type: translations[selectedState],
                  item_id: 'apply_medical_card',
                  origin: 'tax_calculator',
                });
              }}
            />
          ) : null}
        </>
      ) : null}
    </>
  );
};

const TaxSavingsCalculatorBlogPost: React.FC<{
  isBlogPost?: boolean;
  initialState?: string;
}> = ({ isBlogPost, initialState }) => {
  return (
    <div className="grid gap-y-6 bg-green-50 rounded-md p-4 not-prose mb-6">
      <HeadingBody
        className="justify-self-center text-center max-w-lg text-xl font-medium"
        level="4"
        title="See how much you could save with a medical card"
      />
      <TaxSavingsForm isBlogPost={isBlogPost} initialState={initialState} />
    </div>
  );
};

const TaxSavingCalculatorWidget: React.FC<{
  visible: boolean;
  toggleMessage: (toggle: boolean) => void;
}> = ({ visible, toggleMessage }) => {
  const [showForm, setShowForm] = useState<boolean>(false);
  return (
    <div
      className={[
        'relative md:fixed md:bottom-8 md:z-99 bg-white transition-all duration-500 grid gap-y-6 rounded-md border-green-50 border border-2 md:max-w-xs',
        visible
          ? 'max-h-full md:max-h-full md:left-8 md:right-8'
          : 'max-h-0 md:max-h-full md:-left-8 opacity-0',
      ].join(' ')}
    >
      <span className="bg-green-50 rounded-t-md">
        <HeadingBody
          className="p-10 justify-self-center text-center !text-lg font-medium"
          level="4"
          title="See how much you could save with a medical card"
        />
        {!showForm ? (
          <span className="grid px-4 pb-6 -mt-4">
            <Button
              label="Start Calculating"
              variant="primary"
              className="w-full text-lg rounded-lg font-medium font-sans border-green-600 bg-green-600 text-white hover:!border-green hover:!bg-green hover:!text-black transition-all duration-500"
              onClick={event => {
                event.preventDefault();
                setShowForm(true);
              }}
            />
          </span>
        ) : null}
      </span>
      {showForm ? (
        <span className="grid gap-y-4 p-4">
          <TaxSavingsForm />
        </span>
      ) : null}
      <ButtonClose
        onClick={() => toggleMessage(false)}
        className="w-6 mt-2 absolute top-4 right-2"
        size={12}
      />
    </div>
  );
};

const TaxSavingsCalculator: React.FC<{
  isBlogPost?: boolean;
  initialState?: string;
}> = ({ isBlogPost = false, initialState = null }) => {
  const [showMessage, setShowMessage] = React.useState(true);
  return isBlogPost ? (
    <TaxSavingsCalculatorBlogPost
      isBlogPost={isBlogPost}
      initialState={initialState}
    />
  ) : (
    <TaxSavingCalculatorWidget
      visible={showMessage}
      toggleMessage={setShowMessage}
    />
  );
};

export default TaxSavingsCalculator;
