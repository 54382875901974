import React, { useCallback, useMemo, useState } from 'react';
import {
  Button,
  ButtonLayoutProps,
  Form,
  Paragraph,
  SelectFieldMinimal,
} from '@leafwell/components';
import { useWordPressContext } from 'contexts/wordpress-provider';
import { setStateCookie } from 'lib/cookies/state-cookie';
import { StatesListItem, useStatesList } from 'hooks/useStatesList';
import { useCurrentState } from 'hooks/useCurrentState';
import { useEventsTracker } from 'contexts/eventsTracker';

const StateSelect: React.FC<{
  activeState?: string;
  buttonLabel?: string;
  className?: string;
  gridClassName?: string;
  label?: string;
  variant?: 'default' | 'small';
  inlineButton?: boolean;
  buttonVariant?: ButtonLayoutProps['variant'];
  showLoginCta?: boolean;
  isBlogPost?: boolean;
}> = ({
  activeState,
  buttonLabel,
  className,
  gridClassName,
  label,
  variant = 'default',
  inlineButton = true,
  buttonVariant = 'secondary',
  showLoginCta = false,
  isBlogPost = false,
}) => {
  const showButton = useMemo(
    () => !(variant === 'small' && label?.length > 0),
    [variant, label],
  );
  const statesList = useStatesList();
  const defaultState = useCurrentState();
  const { cardPage, statePages, translations } = useWordPressContext();
  const [selectedStateCode, setSelectedStateCode] = useState<string>(
    activeState || defaultState || '',
  );

  const submitForm = useCallback(
    (stateCode?: string) => {
      const hasStateCode = stateCode && stateCode !== '';
      const url = hasStateCode
        ? statePages.find(({ code }) => code === stateCode)?.uri
        : cardPage?.uri;
      if (hasStateCode) {
        setStateCookie(stateCode);
      }

      if (url) {
        window.location.assign(url);
      }
    },
    [cardPage, statePages],
  );
  const { trackEvent } = useEventsTracker();

  const selectedStateObject: StatesListItem = useMemo(
    () => statesList.find(item => item.value === selectedStateCode),
    [statesList, selectedStateCode],
  );

  return statePages?.length ? (
    <div className={['grid gap-y-2', gridClassName].join(' ')}>
      <Form
        className={[
          'sm:self-start gap-x-3',
          showButton
            ? inlineButton
              ? 'sm:grid-cols-inline-form sm:justify-between sm:max-w-md gap-y-2'
              : 'gap-y-2'
            : 'sm:grid-cols-2-auto max-w-md gap-y-1',
          className,
        ].join(' ')}
        defaultValues={{ stateCode: selectedStateCode }}
        onSubmit={() => {
          submitForm(selectedStateCode);
          trackEvent({
            event: 'select_content',
            content_type: translations[selectedStateCode],
            item_id: isBlogPost
              ? 'blog_medical_card_cta'
              : 'apply_medical_card',
            origin: window?.location?.pathname,
          });
        }}
      >
        {label && (
          <Paragraph className="self-center xl:text-3xl">{label}</Paragraph>
        )}
        <SelectFieldMinimal
          defaultValue={selectedStateObject}
          placeholder={translations['Select State']}
          icon="/us_flag.svg"
          options={statesList}
          onSelect={target => {
            setSelectedStateCode(target.value);
            if (!showButton) submitForm(target.value);
          }}
        />
        {showButton && (
          <Button
            {...{
              label: buttonLabel || translations['Get started'],
              rounded: true,
              type: 'submit',
              variant: buttonVariant || 'secondary',
            }}
          />
        )}
      </Form>
      {showLoginCta ? (
        <p className="text-sm">
          Already a Leafwell patient?
          <br />
          <a
            className="font-medium underline underline-offset-3"
            target="_blank"
            href={process.env.NEXT_PUBLIC_LOGIN_URL}
          >
            Log in here
          </a>
        </p>
      ) : null}
    </div>
  ) : null;
};

export default StateSelect;
